import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

const modules = [
  // React Modules
  {
    name: 'InventoryNearYouRoot',
    loader: () => import('./modules/react/inventory-near-you/InventoryNearYouRoot.js'),
    async render(Root, el) {
      const storeInventoryNearYou = await import('./modules/react/inventory-near-you/store');
      createRoot(el[0]).render(<Provider store={storeInventoryNearYou.store}><Root store={storeInventoryNearYou.store} rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'AccessoryInstallationInstructions',
    loader: () => import('./modules/react/accessory-installation-instructions/AccessoryInstallationInstructionsRoot.js'),
    async render(Root, el) {
      const storeAccessory = await import('./modules/react/accessory-installation-instructions/store');
      createRoot(el[0]).render(<Provider store={storeAccessory.store}><Root store={storeAccessory.store} rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'PHOProductsListing',
    loader: () => import('./modules/react/pho-products-listing/PhoProductsListingRoot.js'),
    async render(Root, el) {
      const storePHOProductsListing = await import('./modules/react/pho-products-listing/store');
      createRoot(el[0]).render(<Provider store={storePHOProductsListing.store}><Root store={storePHOProductsListing.store} rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'Configurator',
    loader: () => import('./modules/react/configurator/ConfiguratorRoot.js'),
    async render(Root, el) {
      const storeConfigurator = await import('./modules/react/configurator/store');
      createRoot(el[0]).render(<Provider store={storeConfigurator.store}><Root rootElement={el[0]} /></Provider>);
    },
    styles: () => import('../styles/modules/mod-configurator/_mod-configurator.scss'),
  },
  {
    name: 'ShopAccessories',
    loader: () => import('./modules/react/shop-accessories/ShopAccessoriesRoot.js'),
    async render(Root, el) {
      const store = await import('./modules/react/shop-accessories/store/index.js');
      createRoot(el[0]).render(<Provider store={store.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'OwnersManual',
    loader: () => import('./modules/react/owners-manual/OwnersManualRoot.js'),
    async render(Root, el) {
      const ownersManualStore = await import('./modules/react/owners-manual/store');
      createRoot(el[0]).render(<Provider store={ownersManualStore.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'EventsListing',
    loader: () => import('./modules/react/events-listing/EventsListingRoot.js'),
    async render(Root, el) {
      const eventsListingStore = await import('./modules/react/events-listing/store');
      createRoot(el[0]).render(<Provider store={eventsListingStore.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'DealerLocator',
    loader: () => import('./modules/react/dealer-locator/DealerLocatorRoot.js'),
    async render(Root, el) {
      const dealerLocatorStore = await import('./modules/react/dealer-locator/store');
      createRoot(el[0]).render(<Provider store={dealerLocatorStore.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'BudgetCalculator',
    loader: () => import('./modules/react/budget-calculator/BudgetCalculatorRoot.js'),
    async render(Root, el) {
      const budgetCalculatorStore = await import('./modules/react/budget-calculator/store');
      createRoot(el[0]).render(<Provider store={budgetCalculatorStore.store}><Root rootElement={el[0]} /></Provider>);
    },
  },

  {
    name: 'ModGetAQuote',
    loader: () => import('./modules/react/get-a-quote/GetAQuoteRoot.js'),
    async render(Root, el) {
      const getAQuoteStore = await import('./modules/react/get-a-quote/store');
      createRoot(el[0]).render(<Provider store={getAQuoteStore.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'WallpapersListing',
    loader: () => import('./modules/react/wallpapers-listing/WallpapersListingRoot.js'),
    async render(Root, el) {
      const wallpapersListingStore = await import('./modules/react/wallpapers-listing/store');
      createRoot(el[0]).render(<Provider store={wallpapersListingStore.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'SearchResults',
    loader: () => import('./modules/react/search-results/SearchResultsRoot.js'),
    async render(Root, el) {
      const storeSearchResults = await import('./modules/react/search-results/store');
      createRoot(el[0]).render(<Provider store={storeSearchResults.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'Recalls',
    loader: () => import('./modules/react/recalls/RecallsRoot.js'),
    async render(Root, el) {
      const recallsStore = await import('./modules/react/recalls/store/index.js');
      createRoot(el[0]).render(<Provider store={recallsStore.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'Inventory',
    loader: () => import('./modules/react/inventory/InventoryRoot.js'),
    async render(Root, el) {
      const inventoryStore = await import('./modules/react/inventory/store');
      createRoot(el[0]).render(<Provider store={inventoryStore.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'OffersListing',
    loader: () => import('./modules/react/offers-listing/OffersListingRoot.js'),
    async render(Root, el) {
      const store = await import('./modules/react/offers-listing/store');
      createRoot(el[0]).render(<Provider store={store.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'AvailableOffers',
    loader: () => import('./modules/react/available-offers/AvailableOffersRoot.js'),
    async render(Root, el) {
      const store = await import('./modules/react/available-offers/store');
      createRoot(el[0]).render(<Provider store={store.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'ModelOffersListing',
    loader: () => import('./modules/react/model-offers-listing/ModelOffersListingRoot.js'),
    async render(Root, el) {
      const store = await import('./modules/react/model-offers-listing/store');
      createRoot(el[0]).render(<Provider store={store.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  {
    name: 'TrimOffersDetail',
    loader: () => import('./modules/react/trim-offers-detail/TrimOffersDetailRoot.js'),
    async render(Root, el) {
      const store = await import('./modules/react/trim-offers-detail/store');
      createRoot(el[0]).render(<Provider store={store.store}><Root rootElement={el[0]} /></Provider>);
    },
  },
  // JS Modules
  {
    name: 'TabList',
    loader: () => import('./modules/TabList'),
  },
  {
    name: 'TabChildMenu',
    loader: () => import('./components/header/TabChildMenu'),
  },
  {
    name: 'ModBannerHome',
    loader: () => import('./modules/mod-banner-home/ModBannerHome'),
  },
  // {
  //   name: 'ModelsCards',
  //   styles: () => import('../styles/modules/_mod-model-cards.scss'),
  // },
  {
    name: 'AnimationExpandWidth',
    loader: () => import('./modules/AnimationExpandWidth'),
  },
  {
    name: 'ModSubheader',
    loader: () => import('./modules/ModSubheader'),
  },
  {
    name: 'ModSegmentCards',
    loader: () => import('./modules/ModSegmentCards'),
  },
  {
    name: 'ModCampaign',
    loader: () => import('./modules/ModCampaign'),
  },
  {
    name: 'ModThreeCards',
    loader: () => import('./modules/ModThreeCards'),
  },
  {
    name: 'ModRacing',
    loader: () => import('./modules/ModRacing'),
  },
  {
    name: 'ModAdditionalFeaturesWhite',
    loader: () => import('./modules/ModAdditionalFeaturesWhite'),
  },
  {
    name: 'ModAdditionalFeaturesBlack',
    loader: () => import('./modules/ModAdditionalFeaturesBlack'),
  },
  {
    name: 'ModFullWidthLongImageSocialGrid',
    loader: () => import('./modules/ModFullWidthLongImageSocialGrid'),
  },
  {
    name: 'RelatedPHOProducts',
    loader: () => import('./modules/ModRelatedPHOProducts.js'),
  },
  {
    name: 'ModGallery',
    loader: () => import('./modules/ModGallery'),
  },
  {
    name: 'ModVideoSlideshow',
    loader: () => import('./modules/ModVideoSlideshow'),
  },
  {
    name: 'CampaignScroll',
    loader: () => import('./modules/ModScrollBasedCampaign.js'),
  },
  {
    name: 'ModSpecifications',
    loader: () => import('./modules/ModSpecifications'),
  },
  {
    name: 'ModPanelFeatures',
    loader: () => import('./modules/ModPanelFeatures'),
  },
  {
    name: 'Typography',
    loader: () => import('./components/Typography'),
  },
  {
    name: 'SelectC8',
    loader: () => import('./components/SelectC8'),
  },
  {
    name: 'DropdownLink',
    loader: () => import('./components/DropdownLink'),
  },
  {
    name: 'FormAnimation',
    loader: () => import('./components/FormAnimation'),
  },
  {
    name: 'FormSignUp',
    loader: () => import('./components/FormSignUp'),
  },
  {
    name: 'Popup',
    loader: () => import('./components/Popup'),
  },
  {
    name: 'PopupGallery',
    loader: () => import('./components/PopupGallery'),
  },
  {
    name: 'SelectboxTabList',
    loader: () => import('./components/SelectboxTabList'),
  },
  {
    name: 'ExpandingGallery',
    loader: () => import('./modules/ModExpandingGallery.js'),
  },
  {
    name: 'CategoryModels',
    loader: () => import('./modules/ModCategoryModels'),
  },
  {
    name: 'ModRelatedTrimsAndModels',
    loader: () => import('./modules/ModRelatedTrimsAndModels'),
  },
  {
    name: 'TechnologyTrimCards',
    loader: () => import('./modules/technology-trim-cards/ModTechnologyTrimCards'),
  },
  {
    name: 'ModEventALT',
    loader: () => import('./modules/ModEventALT'),
  },
  {
    name: 'ModEmailSignUp',
    loader: () => import('./modules/ModEmailSignUp'),
  },
  {
    name: 'TrimsSelection',
    loader: () => import('./modules/ModTrimSelection'),
  },
  {
    name: 'Features',
    loader: () => import('./modules/ModFeatures'),
  },
  {
    name: 'SubNavigation',
    loader: () => import('./modules/mod-sub-navigation/ModSubNavigation.js'),
  },
  {
    name: 'ModNewFeatures',
    styles: () => import('../styles/modules/_mod-new-features.scss'),
    loader: () => import('./modules/mod-new-features/ModNewFeatures.js'),
  },
  {
    name: 'ModModelSlider',
    loader: () => import('./modules/ModModelSlider'),
  },
  {
    name: 'ModHorizontalLogoListing',
    loader: () => import('./modules/ModHorizontalLogoListing'),
  },
  {
    name: 'ModTrimBannerHero',
    loader: () => import('./modules/mod-trim-banner-hero/ModTrimBannerHero.js'),
  },
  {
    name: 'ExpandingVideo',
    loader: () => import('./modules/ModExpandingVideo'),
  },
  {
    name: 'RacingTeam',
    loader: () => import('./modules/ModRacingTeam'),
  },
  {
    name: 'ModRacingBanner',
    loader: () => import('./modules/ModRacingBanner'),
  },
  {
    name: 'RacingProfileSub',
    loader: () => import('./modules/ModRacingProfileSub'),
  },
  {
    name: 'ModFaq',
    loader: () => import('./modules/ModFaq'),
  },
  {
    name: 'ModEventDetail',
    loader: () => import('./modules/ModEventDetail'),
  },
  {
    name: 'ModSegmentedVideoSlideshow',
    loader: () => import('./modules/ModSegmentedVideoSlideshow'),
  },
  {
    name: 'ModWarrantyBooklets',
    loader: () => import('./modules/ModWarrantyBooklets'),
  },
  {
    name: 'CategoryShortImageText',
    loader: () => import('./modules/ModCategoryShortImageText'),
  },
  {
    name: 'ModFeaturesHorizontal',
    loader: () => import('./modules/ModFeaturesHorizontal'),
  },
  {
    name: 'ModNeedLicense',
    loader: () => import('./modules/ModNeedLicense'),
  },
  {
    name: 'ResourceCards',
    loader: () => import('./modules/ModResourceCards'),
  },
  {
    name: 'ModInternalMediaBanner',
    loader: () => import('./modules/ModInternalMediaBanner'),
  },
  {
    name: 'ModExploreModelsByCategory',
    loader: () => import('./modules/ModExploreModelsByCategory'),
  },
  {
    name: 'ModBrandCard',
    loader: () => import('./modules/ModBrandCard'),
  },
  {
    name: 'ModTabSlider',
    loader: () => import('./modules/tab-slider/ModTabSlider'),
  },
  {
    name: 'ModTechnology',
    loader: () => import('./modules/ModTechnology'),
  },
  {
    name: 'ModEligibleProducts',
    loader: () => import('./modules/ModEligibleProducts'),
  },
  {
    name: 'ModBecomeDealer',
    loader: () => import('./modules/ModBecomeDealer'),
  },
  {
    name: 'PHOProductDetails',
    loader: () => import('./modules/ModPHOProductDetails.js'),
  },
  {
    name: 'ModBecomeDealerForm',
    loader: () => import('./modules/ModBecomeDealerForm.js'),
  },
  {
    name: 'ConvertTimeZone',
    loader: () => import('./components/ConvertTimeZone.js'),
  },
  {
    name: 'MadeToCustomize',
    loader: () => import('./modules/ModMadeToCustomize'),
  },
  {
    name: 'LoadingScreen',
    loader: () => import('./modules/ModLoadingScreen.js'),
  },
  {
    name: 'ModChart',
    loader: () => import('./modules/ModChart.js'),
  },
  {
    name: 'VehicleAnimation',
    loader: () => import('./modules/ModVehicleAnimation.js'),
  },
  {
    name: 'VehicleAnimationV2',
    loader: () => import('./modules/ModVehicleAnimationV2.js'),
  },
];

export default [...modules];
